import * as Swagger from 'swagger-ui-dist';
import * as cyberApiSwagger from '../../../client-sdk/cyber-api-swagger.json';

export class ApiDocs {
    private docsElement: HTMLElement;

    private attached(): void {
        // Requires polyfill for 'path' using `path-browserify` in Webpack config
        Swagger.SwaggerUIBundle({
            domNode: this.docsElement,
            spec: cyberApiSwagger
        });
    }
}
